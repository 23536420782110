input:focus, textarea:focus {
  outline: none;
}

div[class*="-Historam"] {
  background-color: #6cbace;
  border-left-color: azure;
  border-left-width: 1px;
  border-left-style: dotted;
}

.echarts-for-react {
  height: 100% !important;
}